<template>
    <NuxtLink :to="`/recipes/collections/${collection.id}`">
        <figure
            class="h-full rounded-2xl border-2 hover:border-primary-200 overflow-hidden bg-white dark:bg-fill-secondary flex flex-col dark:border-none">
            <div class="custom-img-container md:h-[176px] relative">
                <nuxt-img sizes="xs:100vw sm:50vw md:33vw lg:25vw" width="1024" height="800" quality="75"
                    class="animatable-img w-full object-cover" format="webp" :src="currentImage" loading="lazy"
                    placeholder />
                <div class="absolute bottom-4 left-0 w-10 h-1 rounded-full">
                    <div class="absolute left-6 w-10 h-1 bg-white dark:bg-gray-500 opacity-50 rounded-full"></div>
                    <div class="progress-bar-transition absolute left-6 bg-white dark:bg-gray-200 h-1 rounded-full"
                        :style="{ width: progress + 'px' }">
                    </div>
                </div>
            </div>
            <div class="flex items-end p-6">
                <div class="space-y-1">
                    <p class="text-3xl text-gray-900 dark:text-gray-100">{{ collection.title }}</p>
                    <p class="text-md text-gray-600 dark:text-gray-400">{{ collection.subtitle }}</p>
                </div>
                <p
                    class="text-xs text-gray-600 dark:text-gray-400 rounded border dark:border-gray-600 px-3 py-1 text-nowrap">
                    {{ collection.count }} Recettes
                </p>
            </div>
        </figure>
    </NuxtLink>
</template>

<script>
export default {
    props: {
        collection: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            currentImageIndex: 0,
            intervalId: null,
            progress: 0
        }
    },
    computed: {
        currentImage() {
            return this.collection.images[this.currentImageIndex];
        }
    },
    mounted() {
        this.startImageRotation();
    },
    beforeDestroy() {
        this.stopImageRotation();
    },
    methods: {
        startImageRotation() {
            const randomNum = Math.floor(Math.random() * 3) + 1;

            // Start the progress bar update interval
            this.intervalId = setInterval(() => {
                this.updateProgress();
            }, randomNum * 50); // Update progress
        },
        updateProgress() {
            this.progress += 1; // Increase progress by 1px every 100ms (4 seconds per full cycle to reach 40px)
            if (this.progress >= 40) {
                this.progress = 0; // Reset progress
                this.currentImageIndex = (this.currentImageIndex + 1) % this.collection.images.length; // Move to the next image
            }
        },
        stopImageRotation() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        }
    }
}
</script>

<style scoped>
.custom-img-container {
    height: 332px !important;
}

.progress-bar-transition {
    transition: width 0.1s linear;
}

a {
    @apply text-black dark:text-white no-underline;
}
</style>