<template>
    <div class="px-6 xl:px-0 pb-40 mx-auto max-w-[1045px]">
        <main class="pt-6 md:pt-10">
            <h1 class="hidden" style="position: static;">Simply Wafa: Des recettes sucrées pour vous régaler.
            </h1>

            <section class="space-y-6 pb-12">
                <div class="flex justify-center">
                    <h1 class="text-4xl font-bold max-w-[600px] text-center dark:text-white">Révélez le chef
                        qui est en vous</h1>
                </div>
                <div class="flex justify-center">
                    <p class="text-center text-pretty text-xl font-semibold max-w-[600px] dark:text-white">
                        Des recettes simples aux techniques avancées pour créer des gourmandises inoubliables, en toute
                        confiance.
                    </p>
                </div>
            </section>

            <div class="space-y-20">
                <div v-if="recipesOnTheSpotlight" class="space-y-6">
                    <h2 class="text-2xl md:text-3xl pt-8 dark:text-white font-semibold">Recettes Populaires
                    </h2>
                    <section class="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-9">
                        <div v-for="recipe in recipesOnTheSpotlight" class="h-full">
                            <RecipeCardM :recipe="recipe" class="h-full flex flex-col flex-grow min-h-card" />
                        </div>
                    </section>
                    <Newsletter />
                </div>

                <div id="recipe-collections" class="lazy-loading">
                    <div v-if="collections" class="space-y-6">
                        <h2 class="text-2xl md:text-3xl font-semibold dark:text-white">Collections Organisées</h2>
                        <section class="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-9">
                            <div v-for="collection in collections" class="h-full">
                                <RecipeCardCollection :collection="collection" class="h-full flex flex-col flex-grow" />
                            </div>
                        </section>
                    </div>
                </div>

                <div id="recent-recipes" class="lazy-loading">
                    <section v-if="recipes" class="space-y-6">
                        <h2 class="text-2xl md:text-3xl font-semibold dark:text-white">Dernières Recettes</h2>
                        <div class="mx-0 md:mx-[-16px]">
                            <div class="w-full sm:w-full px-0 md:px-[-160px]">
                                <vue-horizontal class="vue-horizontal">
                                    <div v-for="(recipe, index) in recipes" :key="recipe.id">
                                        <div class="w-64"
                                            :style="{ marginLeft: '1rem', marginRight: index === (recipe.length - 1) ? '1rem' : '0' }">
                                            <RecipeCardS :recipe="recipe" />
                                        </div>
                                    </div>
                                </vue-horizontal>
                            </div>
                        </div>
                    </section>
                </div>

                <div id="recent-articles" class="lazy-loading">
                    <section v-if="articles" class="space-y-6">
                        <h2 class="text-2xl md:text-3xl font-semibold dark:text-white">Derniers Articles</h2>
                        <div class="mx-0 md:mx-[-16px]">
                            <div class="w-full sm:w-full px-0 md:px-[-160px]">
                                <vue-horizontal class="vue-horizontal">
                                    <div v-for="(article, index) in articles" :key="article.id">
                                        <div class="w-64"
                                            :style="{ marginLeft: '1rem', marginRight: index === (articles.length - 1) ? '1rem' : '0' }">
                                            <ArticleCardS :article="article" />
                                        </div>
                                    </div>
                                </vue-horizontal>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import VueHorizontal from "vue-horizontal";

let recipes = ref(null);
let recipesOnTheSpotlight = ref(null);
let collections = ref([])
let articles = ref(null);

const loadData = async () => {
    let url = `/api/recipes/recent`;

    const response = await $fetch(url);
    recipes.value = response.recipes;
};

const loadRecipesOnTheSpotlight = async () => {
    const response = await $fetch(`/api/recipes/spotlight`);
    const recipes = response.recipes;

    if (recipes && recipes.length > 0) {
        recipesOnTheSpotlight.value = recipes;
    }
};

const loadRecentArticles = async () => {
    const url = `/api/articles/all`;
    const response = await $fetch(url);
    articles.value = response.articles;
};

const fetchCollections = async () => {
    try {
        const response = await fetch('/data/collections.json');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Transform the data to include category names
        collections.value = Object.values(data);
    } catch (error) {
        console.error('Error fetching ingredients:', error);
    }
};

await loadRecipesOnTheSpotlight();

onMounted(() => {
    if (process.client) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Load the section content here
                    loadSectionContent(entry.target.id);

                    // Stop observing once the section is loaded
                    observer.unobserve(entry.target);
                }
            });
        }, {
            // Optional configuration:
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0.1, // Trigger when 10% of the section is visible
        });

        // Get the section you want to observe
        const elements = document.querySelectorAll('.lazy-loading');

        // Iterate over the NodeList and perform actions on each element
        elements.forEach(element => {
            // Start observing the section
            observer.observe(element);
        });

        async function loadSectionContent(id) {
            if (id === 'recipe-collections') {
                await fetchCollections();
            } else if (id === 'recent-recipes') {
                await loadData();
            } else if (id === 'recent-articles') {
                await loadRecentArticles();
            }
        }
    }
})
</script>