<template>
    <aside
        class="flex flex-col md:flex-row justify-around space-y-8 md:space-y-0 md:items-center bg-complementary-light dark:bg-complementary-dark rounded-2xl px-8 py-20 md:py-10">
        <p class="text-xl text-center md:text-left dark:text-white">Recevez des recettes et conseils chaque semaine
            !</p>
        <div class="flex flex-col md:flex-row space-y-2.5 md:space-y-0 rounded-xl">
            <input class="px-4 h-12 rounded-xl dark:bg-fill-primary" type="text" placeholder="Votre e-mail"
                id="newsletter-email-field">
            <button
                class="ml-0 md:ml-2 px-8 bg-accent-light dark:bg-accent-dark md:border md:border-white text-black dark:text-white font-semibold rounded-xl h-12 dark:border-none"
                type="button" @click="joinNewsletter">S'abonner</button>
        </div>
    </aside>
</template>

<script setup>
async function joinNewsletter() {
    const inputField = document.getElementById("newsletter-email-field");
    const email = inputField.value;

    if (validateEmail(email)) {
        const response = await fetch(`/api/newsletter/subscribe?email=${email}`);
        const data = await response.json();

        alert(data.message);
        inputField.value = "";
    } else {
        alert("Votre e-mail est invalide, veuillez le vérifier et essayer à nouveau.");
    }
}

function validateEmail(email) {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
}
</script>